* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: #f1f5f8;
  color: #222222;
}

.booklist {
  width: 90vw;
  max-width: 960px;
  margin: 5rem auto;
  display: grid;
  gap: 1.5rem;
}

@media screen and (min-width: 360px) {
  .booklist {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 768px) {
  .booklist {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .booklist {
    grid-template-columns: repeat(4, 1fr);
  }
}

.book {
  background: #fff;
  padding: 0.5rem;
  text-align: center;
  position: relative;
}

.book img {
  width: 100%;
  object-fit: cover;
}

.book h5 {
  color: #617d98;
  letter-spacing: 2px;
}

.number {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  font-size: 1rem;
  padding: 0.75rem;
  /* border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem; */
  background: #c35600;
  color: #fff;
}

.title {
  text-align: center;
  margin-block-start: 60px;
  color: #c35600;
}
